import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageCheck'
})
export class ImageCheckPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let removeSpace = value.replace(/\s\]/g, ']');
    let replaceCapital = removeSpace.replace(/\[Img:/g, '[img:');
    let replaceImage = replaceCapital.replace(/\[img:([^\]]+)\]/g, '<img src="https://eduauraapublic.s3.ap-south-1.amazonaws.com/subjimages/sub_all_images_final/$1.PNG" class="subjective-img">'); 
    return replaceImage.replace(/\[BR\]\\*/g, '<br>');
  }

}
