import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCheckPipe } from './image-check.pipe';

@NgModule({
  declarations: [ImageCheckPipe],
  imports: [
    CommonModule
  ],
  exports: [
    ImageCheckPipe
  ]
})
export class ImageCheckModule { }
